import { useRef } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { TextField } from "@rmwc/textfield"
import { Typography } from "@rmwc/typography"
import { Button } from "@rmwc/button"
import "@rmwc/textfield/styles"
import "@rmwc/button/styles"
import "@rmwc/typography/styles"
import "./App.css"

// Gmail removes "text-decoration: none" but not "text-decoration: unset".

function setClipboard(text) {
	const type = "text/html"
	const blob = new Blob([text], { type })
	const data = [new window.ClipboardItem({ [type]: blob })]

	navigator.clipboard.write(data).then(
		function() {
			alert("Your signature is copied to your clipboard!")
		},
		function() {
			alert("An error happened, please contact dev team.")
		},
	)
}

const validationSchema = yup.object({
	name: yup.string(),
	position: yup.string(),
	emailAddress: yup.string(),
})

const App = () => {
	const signatureRef = useRef(null)
	const formik = useFormik({
		initialValues: {
			name: "",
			position: "",
			emailAddress: "",
		},
		validationSchema: validationSchema,
		onSubmit: async values => {
			const html = signatureRef.current.innerHTML
			setClipboard(html)
		},
	})

	return (
		<div>
			<form className="signature-wrapper" onSubmit={ formik.handleSubmit }>
				<div className="signature-items-wrapper">
					<Typography use="headline2">{ "Genki Email Signature Generator" }</Typography>
				</div>
				<div className="signature-items-wrapper text-field-width">
					<TextField
						outlined
						className="text-field-width"
						id="name"
						name="name"
						label="Name"
						value={ formik.values.name }
						onChange={ formik.handleChange }
					/>
				</div>
				<div className="signature-items-wrapper text-field-width">
					<TextField
						outlined
						className="text-field-width"
						id="position"
						name="position"
						label="Position"
						value={ formik.values.position }
						onChange={ formik.handleChange }
					/>
				</div>
				<div className="signature-items-wrapper text-field-width">
					<TextField
						outlined
						className="text-field-width"
						id="emailAddress"
						name="emailAddress"
						label="Email Address"
						value={ formik.values.emailAddress }
						onChange={ formik.handleChange }
					/>
				</div>
				<div className="signature-items-wrapper">
					<Button label="Copy your Email Signature" outlined type="submit" />
				</div>
			</form>
			<div ref={ signatureRef } className="signature-wrapper">
				<div className="signature-show-wrapper" style={ { color: "#202020" } }>
					<div>
						<b>{ formik.values.name }</b>
						<br />
						{ formik.values.position }
						<br />
					</div>
					<div style={ { margin: "16px 0" } }>
						<img
							width="100"
							height="41"
							alt="Genki"
							src="https://email-static.genki.world/logo-v2.png"
						/>
					</div>
					<div style={ { margin: "8px 0" } }>
						<b>Make your health a top priority wherever you travel.</b>
					</div>
					<a href="https://www.trustpilot.com/review/genki.world"
					   style={ {
						   alignItems: "center",
						   color: "#202020",
						   cursor: "pointer",
						   display: "flex",
						   lineHeight: "20px",
						   margin: "8px auto 8px 0",
						   textDecoration: "unset",
						   width: "fit-content",
					   } }
					   target="_blank">
						<span>Travelers love us&nbsp;&nbsp;</span>
						<img alt="" height="18" src="https://genki.world/dynamic/trustpilot-stars.png" width="96" />
						<span>&nbsp;&nbsp;on&nbsp;&nbsp;</span>
						<img alt="" height="20" src="https://email-static.genki.world/trustpilot.png" width="81" />
					</a>
					<div style={ { margin: "8px 0" } }>
						<a href="https://genki.world/" style={ { textDecoration: "unset" } } target="_blank">
							<font color="#287199">genki.world</font>
						</a>{ " " }
						•{ " " }
						<a href={ `mailto:${ formik.values.emailAddress }` } style={ { textDecoration: "unset" } } target="_blank">
							<font color="#287199">{ formik.values.emailAddress }</font>
						</a>
					</div>
					<div style={ { color: "rgb(153,153,153)" } }>
						Genki UG (haftungsbeschränkt)
						<br />
						Dorothee-Sölle-Platz 2, 50672 Cologne, Germany
					</div>
					<div style={ { color: "rgb(153,153,153)" } }>
						Managing directors are Marc Knaup and Mehrdad Piroozram.
						<br />
						Registration number HRB 106969 in the District Court Cologne.
						<br />
					</div>
					<div style={ { color: "rgb(153,153,153)" } }>Registration number D-TUKU-OFIBM-34 in the Broker Register.</div>
				</div>
			</div>
		</div>
	)
}

export default App
